.BBPMenuBar {
    background-color: #fff;
    padding: 10px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px #15223214;
}

.BBPMenuBar .BBPMInner {
    display: flex;
    align-items: center;
}

.BBPMenuBar .BBPMInner .BBPMNU {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.BBPMenuBar .BBPMInner .BBPMNU .BBPMNUInnner {
    display: flex;
    align-items: center;
}