.BBPPWAUPITypes {
    display: flex;
    flex-direction: column;
    height: 100%; 
}
 
.BBPPWAUPITypes .BBPPWAUPITTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #000;
    text-transform: capitalize;
    padding: 10px;
}

.BBPPWAUPITypes .BBPPWAUPIInfo {
    flex: 1;
    overflow: auto;
    padding: 10px;
}

.BBPPWAUPITypes .BBPPWAUPIBtn {
    padding: 10px;
    display: flex;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot {
    margin: 0;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px;
    padding-right: 14px;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot .BBPPWAUPIILable {
    flex: 1;
    display: flex;
    align-items: center;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot .BBPPWAUPIILable strong {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-right: 9px;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot .BBPPWAUPIILable > span {
    display: flex;
    padding: 4px;
    font-size: 24px;
    border: 1px solid #f2f0f0;
    border-radius: 4px;
}