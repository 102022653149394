.BBPPWATransTab {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BBPPWATransTab .BBPPWATTTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #000;
    text-transform: capitalize;
    padding: 10px;
}

.BBPPWATransTab .BBPPWATBody {
    flex: 1 1;
    overflow: auto;
}

.BBPPWATransTab .BBPPWATBody .BBPPWATBList {
    height: 100%;
    overflow: auto;
}

.BBPPWATBList .BBPPWATBLItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.BBPPWATBList .BBPPWATBLItem:last-child {
    border-bottom: 0;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner {
    display: flex;
    align-items: center;
    width: 100%;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo {
    flex: 1;
    overflow: auto;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIITitle {
    display: flex;
    padding-bottom: 5px;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIITitle .BBPPWATBLIIText {
    flex: 1;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    color: #1976d2;
    padding-right: 5px;
    text-transform: capitalize;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIITitle .BBPPWATBLIIPrice {
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    color: #2e7d32;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV {
    display: flex;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIDate {
    flex: 1;
    font-size: 11px;
    line-height: normal;
    color: #848484;
}

.BBPPWATBList .BBPPWATBLItem .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIStatus {
    font-size: 10px;
    line-height: normal;
    border-radius: 8px;
    color: #fff;
    padding: 3px 7px;
    min-width: 63px;
    text-align: center;
}

.BBPPWATBList .BBPPWATBLItem.Success .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIStatus {
    background-color: #2e7d32;
}

.BBPPWATBList .BBPPWATBLItem.Pending .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIStatus {
    background-color: #ed6c02;
}

.BBPPWATBList .BBPPWATBLItem.Failed .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIStatus {
    background-color: #ee2505;
}

.BBPPWATBList .BBPPWATBLItem.Canceled .BBPPWATBLIInner .BBPPWATBLIInfo .BBPPWATBLIIDV .BBPPWATBLIIStatus {
    background-color: #d32f2f;
}

.BBPBPPTILPWA .BBPForm form.rjsf .field-object>fieldset .form-group {
    width: 100%;
}

.BBPPWATransInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPPWATransInfo .BBPPWATTHead {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.BBPPWATransInfo .BBPPWATTHead .BBPPWATTHTitle {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    color: #000;
}

.BBPPWATransInfo .BBPPWATTHead button {
    position: absolute;
    top: 9px;
    left: 10px;
    font-size: 24px;
    padding: 0;
}

.BBPPWATransInfo .BBPPWATBody {
    flex: 1;
    overflow: auto;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWASucTitle {
    text-align: center;
    line-height: normal;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
}


.BBPPWATransInfo .BBPPWATBody .BBPPWASucTitle.Success {
    background-color: #2e7d32;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWASucTitle.Pending {
    background-color: #ed6c02;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWASucTitle.Failed {
    background-color: #ee2505;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWASucTitle.Canceled {
    background-color: #d32f2f;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWABiller {
    display: flex;
    align-items: center;
    padding: 10px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWABiller .BBPPWABIcon {
    display: flex;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWABiller .BBPPWABInfo {
    flex: 1;
    padding-left: 10px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWABiller .BBPPWABInfo .BBPPWABITitle {
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    color: #000;
    padding-bottom: 3px;
    text-transform: capitalize;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWABiller .BBPPWABInfo .BBPPWABIDate {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #6c6a6a;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWARow {
    display: flex;
    padding: 5px 10px;
    border-bottom: 1px solid #f6f8fc;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWARow .BBPPWARCol1 {
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    min-width: 125px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWARow .BBPPWARCol2 {
    flex: 1;
    text-align: right;
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    color: #787878;
}


.BBPPWATransInfo .BBPPWATBody .BBPPWATtD {
    display: flex;
    background-color: #f6f8fc;
    padding: 7px 10px;
    line-height: normal;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATtD .BBPPWATtDKName {
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    min-width: 125px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATtD .BBPPWATtDTitle {
    flex: 1 1;
    text-align: right;
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    color: #787878;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPD {
    background-color: #f6f8fc;
    padding: 5px 10px;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow {
    background-color: #f6f8fc;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner {
    background-color: #ffffff;
    display: flex;
    padding: 15px 10px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRName {
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    flex: 1 1;
    color: #808080;
    text-transform: capitalize;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRMo {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    min-width: 100px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo {
    flex: 1;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo .BBPPWATPDRIBank {
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
    color: #000;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo .BBPPWATPDRITiD {
    font-size: 11px;
    line-height: normal;
    font-weight: 500;
    padding-top: 3px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF {
    padding: 10px;
    background-color: #f6f8fc;
    display: flex;
    align-items: center;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF .BBPPWATRFInfo {
    flex: 1;
    padding-right: 10px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF .BBPPWATRFIcon {
    display: flex;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF .BBPPWATRFIcon img {
    width: 40px;
    height: 40px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF .BBPPWATRFInfo .BBPPWATRFITitle {
    font-size: 12px;
    font-weight: 400;
    color: #504f4f;
    line-height: normal;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATRF .BBPPWATRFInfo .BBPPWATPDRITiD {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    line-height: normal;
    padding-top: 3px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATBtn {
    padding: 10px;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATBtn button {
    text-transform: capitalize;
    padding: 10px;
    background-color: #1976d2;
    font-size: 14px;
    font-weight: 400;
}

.BBPPWATransTab .BBPPWATBody .BBPPWATNot {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPPWATransInfo .BBPPWATBody .BBPPWATBtn button.BBPPWATBRC {
    background-color: #fff;
    border: 2px solid #1976d2;
}


@media only screen and (max-width:767px) {

    .BBPPWATransInfo .BBPPWATBody,
    .BBPPWATransTab .BBPPWATBody .BBPPWATBList {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .BBPPWATransInfo .BBPPWATBody::-webkit-scrollbar,
    .BBPPWATransTab .BBPPWATBody .BBPPWATBList::-webkit-scrollbar {
        display: none;
    }
}