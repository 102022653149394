.BBPPWAComplTab {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BBPPWAComplTab .BBPPWACTTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #000;
    text-transform: capitalize;
    padding: 10px;
}

.BBPPWAComplTab .BBPPWACBody {
    flex: 1 1;
    overflow: auto;
}

.BBPPWAComplTab .BBPPWACBody .BBPPWACBList {
    height: 100%;
    overflow: auto;
}

.BBPPWACBList .BBPPWACBLItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.BBPPWACBList .BBPPWACBLItem:last-child {
    border-bottom: 0;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner {
    display: flex;
    align-items: center;
    width: 100%;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo {
    flex: 1;
    overflow: auto;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIITitle {
    display: flex;
    padding-bottom: 5px;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIITitle .BBPPWACBLIIText {
    flex: 1;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    color: #1976d2;
    text-transform: capitalize;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIITitle .BBPPWACBLIIPrice {
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    color: #2e7d32;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV {
    display: flex;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV .BBPPWACBLIIDate {
    flex: 1;
    font-size: 11px;
    line-height: normal;
    color: #848484;
}

.BBPPWACBList .BBPPWACBLItem .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV .BBPPWACBLIIStatus {
    font-size: 10px;
    line-height: normal;
    border-radius: 8px;
    color: #fff;
    padding: 3px 7px;
    min-width: 63px;
    text-align: center;
}

.BBPPWACBList .BBPPWACBLItem.Completed .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV .BBPPWACBLIIStatus {
    background-color: #2e7d32;
}

.BBPPWACBList .BBPPWACBLItem.Pending .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV .BBPPWACBLIIStatus {
    background-color: #ed6c02;
}

.BBPPWACBList .BBPPWACBLItem.UnderProcess .BBPPWACBLIInner .BBPPWACBLIInfo .BBPPWACBLIIDV .BBPPWACBLIIStatus {
    background-color: #ffbf00;
}

.BBPBPPTILPWA .BBPForm form.rjsf .field-object>fieldset .form-group {
    width: 100%;
}

.BBPPWATransInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPPWATransInfo .BBPPWATTHead {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.BBPPWATransInfo .BBPPWATTHead .BBPPWATTHTitle {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    color: #000;
}

.BBPPWATransInfo .BBPPWATTHead button {
    position: absolute;
    top: 9px;
    left: 10px;
    font-size: 24px;
    padding: 0;
}

.BBPPWATransInfo .BBPPWACBody {
    flex: 1;
    overflow: auto;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWASucTitle {
    text-align: center;
    line-height: normal;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
}


.BBPPWATransInfo .BBPPWACBody .BBPPWASucTitle.Success {
    background-color: #2e7d32;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWASucTitle.Pending {
    background-color: #ed6c02;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWASucTitle.Failed {
    background-color: #ee2505;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWASucTitle.Canceled {
    background-color: #d32f2f;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWABiller {
    display: flex;
    align-items: center;
    padding: 10px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWABiller .BBPPWABIcon {
    display: flex;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWABiller .BBPPWABInfo {
    flex: 1;
    padding-left: 10px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWABiller .BBPPWABInfo .BBPPWABITitle {
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    color: #000;
    padding-bottom: 3px;
    text-transform: capitalize;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWABiller .BBPPWABInfo .BBPPWABIDate {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #6c6a6a;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWARow {
    display: flex;
    padding: 5px 10px;
    border-bottom: 1px solid #f6f8fc;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWARow .BBPPWARCol1 {
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    min-width: 125px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWARow .BBPPWARCol2 {
    flex: 1;
    text-align: right;
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    color: #787878;
}


.BBPPWATransInfo .BBPPWACBody .BBPPWATtD {
    display: flex;
    background-color: #f6f8fc;
    padding: 7px 10px;
    line-height: normal;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATtD .BBPPWATtDKName {
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    min-width: 125px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATtD .BBPPWATtDTitle {
    flex: 1 1;
    text-align: right;
    line-height: normal;
    font-size: 14px;
    font-weight: 500;
    color: #787878;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPD {
    background-color: #f6f8fc;
    padding: 5px 10px;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow {
    background-color: #f6f8fc;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner {
    background-color: #ffffff;
    display: flex;
    padding: 15px 10px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRName {
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    flex: 1 1;
    color: #808080;
    text-transform: capitalize;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRMo {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    min-width: 100px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo {
    flex: 1;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo .BBPPWATPDRIBank {
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
    color: #000;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATPDRow .BBPPWATPDRInner .BBPPWATPDRInfo .BBPPWATPDRITiD {
    font-size: 11px;
    line-height: normal;
    font-weight: 500;
    padding-top: 3px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF {
    padding: 10px;
    background-color: #f6f8fc;
    display: flex;
    align-items: center;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF .BBPPWATRFInfo {
    flex: 1;
    padding-right: 10px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF .BBPPWATRFIcon {
    display: flex;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF .BBPPWATRFIcon img {
    width: 40px;
    height: 40px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF .BBPPWATRFInfo .BBPPWATRFITitle {
    font-size: 12px;
    font-weight: 400;
    color: #504f4f;
    line-height: normal;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATRF .BBPPWATRFInfo .BBPPWATPDRITiD {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    line-height: normal;
    padding-top: 3px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATBtn {
    padding: 10px;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATBtn button {
    text-transform: capitalize;
    padding: 10px;
    background-color: #1976d2;
    font-size: 14px;
    font-weight: 400;
}

.BBPPWAComplTab .BBPPWACBody .BBPPWACNot {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPPWATransInfo .BBPPWACBody .BBPPWATBtn button.BBPPWATBRC {
    background-color: #fff;
    border: 2px solid #1976d2;
}

@media only screen and (max-width:767px) {
    .BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol {
        width: 100%;
    }

    .BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow {
        flex-wrap: wrap;
    }

    .BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow .BBPRCDVRCPDRCTitle {
        padding-right: 0;
        width: 100%;
    }

    .BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow .BBPRCDVRCPDRCSTitle {
        width: 100%;
        text-align: left;
    }

    .BBPRCDialog .BBPRCDView,
    .BBPPWAComplTab .BBPPWACBody .BBPPWACBList {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .BBPRCDialog .BBPRCDView::-webkit-scrollbar,
    .BBPPWAComplTab .BBPPWACBody .BBPPWACBList::-webkit-scrollbar {
        display: none;
    }
}