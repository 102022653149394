.BBPBillers {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPBillers .BBPBBody {
    flex: 1;
    padding-top: 16px;
    overflow: auto;
}

.BBPBillers .BBPBHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.BBPBillers .BBPBHead .BBPBHBtn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
}

.BBPBillers .BBPBHead .BBPBHBtn a.BBPTableBtn,
.BBPBillers .BBPBHead .BBPBHBtn button.BBPTableBtn {
    margin-right: 24px;
}

.BBPBillerAdd {
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPBillerAdd .BBPBAHead {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.BBPBillerAdd .BBPBAHead .BBPBAHTitle {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    letter-spacing: .5px;
}

.BBPBillerAdd .BBPBABody {
    height: 100%;
    overflow: auto;
}

.BBPBillerAdd .BBPBABody .BBPBABPannel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPBillerAdd .BBPBABody .BBPBABPannel .BBPBABPInner {
    flex: 1 1;
    overflow: auto;
    padding: 10px 0px;
}

.BBPBABPannel .BBPBABPInner .BBPBABPIFields {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
}

.BBPBABPannel .BBPBABPInner .BBPBABPIFields .BBPBABPIFField {
    width: calc(25% - 20px);
    margin: 10px;
}

.BBPBABPIFields .BBPBABPIFField .BBPBABPIFLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #171725;
    display: block;
    margin-bottom: 10px;
}

.BBPBABPIFields .BBPBABPIFField .BBPBABPIFLabel span {
    color: #ee2505;
}

.BBPBillerAdd .BBPBABody .BBPBABPannel .BBPBABPBtns {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.BBPBillerAdd .BBPBABody .BBPBABPannel .BBPBABPBtns button {
    margin: 0 15px;
}

.BBPBillerAdd .BBPBABody .BBPBABPannel .BBPBABPErr {
    text-align: center;
    font-size: 18px;
    color: #d32f2f;
    font-weight: 600;
}

.BBPBABPIFields .BBPBABPIFField .BBPBABPIFImg {
    padding: 12px 25px;
    font-size: 16px;
    line-height: 21px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    background-color: unset;
    box-shadow: unset;
}

.BBPBABPIFields .BBPBABPIFField .BBPBABPIFImg span.MuiButton-endIcon svg {
    font-size: 30px;
}

.BBPBABPannel .BBPBABPInner .BBPBABPIFields .BBPBABPIFField.BBPBABPIFFU {
    width: auto;
}

.BBPBABPannel .BBPBABPInner .BBPBABPIFields .BBPBABPIFFUImg {
    margin: 10px;
    width: 100px;
    height: 87px;
    border: 1px solid #ddd;
}

.BBPBABPannel .BBPBABPInner .BBPBABPIFields .BBPBABPIFFUImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.BBPVBMDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPVBMDialog .BBPVBMDHead {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
}

.BBPVBMDialog .BBPVBMDHead .BBPVBMDHTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    flex: 1;
    line-height: normal;
}

.BBPVBMDialog .BBPVBMDInfo {
    flex: 1;
    overflow: auto;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    padding-bottom: 0;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIHead .BBPVBMDIHIcon {
    display: flex;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIHead .BBPVBMDIHIcon img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIHead .BBPVBMDIHTitle {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIRow {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIRow .BBPVBMDIRCol {
    padding: 5px 15px;
    width: 50%;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIRow .BBPVBMDIRCol .BBPVBMDIRCLabel {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPVBMDialog .BBPVBMDInfo .BBPVBMDIRow .BBPVBMDIRCol .BBPVBMDIRCTitle {
    font-size: 14px;
    color: #000;
    line-height: normal;
    text-transform: capitalize;
}

.BBPVBMDialog .BBPVBMDBtn {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .BBPBillers .BBPBHead {
        display: block;
    }

    .BBPBillers .BBPBHead .BBPBHBtn {
        justify-content: flex-end;
        padding-top: 10px;
        padding-left: 0;
    }
}