.BBPCommission {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPCommission .BBPCHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.BBPCommission .BBPCHead .BBPCHBtn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
}

.BBPCommission .BBPCHead .BBPCHBtn a.BBPTableBtn,
.BBPCommission .BBPCHead .BBPCHBtn button.BBPTableBtn {
    margin-right: 24px;
}

.BBPCommission .BBPBBody {
    flex: 1;
    padding-top: 16px;
    overflow: auto;
}

.BBPCommissionAdd {
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPCommissionAdd .BBPCAHead {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.BBPCommissionAdd .BBPCAHead .BBPCAHTitle {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    letter-spacing: .5px;
}

.BBPCommissionAdd .BBPCABody {
    height: 100%;
    overflow: auto;
}

.BBPCommissionAdd .BBPCABody .BBPCABPannel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPCommissionAdd .BBPCABody .BBPCABPannel .BBPCABPInner {
    flex: 1 1;
    overflow: auto;
    padding: 10px 0px;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields .BBPCABPIFField {
    width: calc(25% - 20px);
    margin: 10px;
}

.BBPCABPIFields .BBPCABPIFField .BBPBABPIFLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #171725;
    display: block;
    margin-bottom: 10px;
}

.BBPCABPIFields .BBPCABPIFField .BBPBABPIFLabel span {
    color: #ee2505;
}

.BBPCommissionAdd .BBPCABody .BBPCABPannel .BBPBABPBtns {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.BBPCommissionAdd .BBPCABody .BBPCABPannel .BBPBABPBtns button {
    margin: 0 15px;
}

.BBPCommissionAdd .BBPCABody .BBPCABPannel .BBPBABPErr {
    text-align: center;
    font-size: 18px;
    color: #d32f2f;
    font-weight: 600;
}

.BBPCABPIFields .BBPCABPIFField .BBPBABPIFImg {
    padding: 12px 25px;
    font-size: 16px;
    line-height: 21px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    background-color: unset;
    box-shadow: unset;
}

.BBPCABPIFields .BBPCABPIFField .BBPBABPIFImg span.MuiButton-endIcon svg {
    font-size: 30px;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields .BBPCABPIFField.BBPBABPIFFU {
    width: auto;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields .BBPBABPIFFUImg {
    margin: 10px;
    width: 100px;
    height: 87px;
    border: 1px solid #ddd;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields .BBPBABPIFFUImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.BBPVCMDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPVCMDialog .BBPVCMDHead {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
}

.BBPVCMDialog .BBPVCMDHead .BBPVCMDHTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    flex: 1;
    line-height: normal;
}

.BBPVCMDialog .BBPVCMDInfo {
    flex: 1;
    overflow: auto;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    padding-bottom: 0;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIHead .BBPVCMDIHIcon {
    display: flex;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIHead .BBPVCMDIHIcon img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    object-position: center;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIHead .BBPVCMDIHTitle {
    padding-left: 15px;
    font-size: 16px;
    font-weight: 600;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow .BBPVCMDIRCol {
    padding: 5px 15px;
    width: 50%;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow .BBPVCMDIRCol.BBPVCMDIRCFull {
    width: 100%;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow .BBPVCMDIRCol .BBPVCMDIRCLabel {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    line-height: normal;
    margin-bottom: 5px;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow .BBPVCMDIRCol .BBPVCMDIRCTitle {
    font-size: 14px;
    color: #000;
    line-height: normal;
    text-transform: capitalize;
}

.BBPVCMDialog .BBPVCMDBtn {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
}

.BBPCABPannel .BBPCABPInner .BBPCABPIFields.BBPBABPIRF .BBPCABPIFField {
    width: calc(22% - 13px);
}

.BBPBABPIRF .BBPBABPIFAddRemove {
    display: flex;
    align-items: center;
    margin: 10px;
    padding-top: 30px;
}

.BBPVCMDialog .BBPVCMDInfo .BBPVCMDIRow .BBPVCMDIRCol .BBPVCMDIRCTitle .BBPVCMDIRCTText {
    margin-top: 5px;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .BBPCommission .BBPCHead {
        display: block;
    }

    .BBPCommission .BBPCHead .BBPCHBtn {
        justify-content: flex-end;
        padding-top: 10px;
        padding-left: 0;
    }
}