.BBPRefundDialog .BBPRDHead {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
}

.BBPRefundDialog .BBPRDHead .BBPRDHTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    flex: 1;
    line-height: normal;
}

.BBPRefundDialog .BBPRDBody {
    flex: 1 1;
    overflow: auto;
    padding: 7.5px;
}

.BBPRefundDialog .BBPRDBtn {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    grid-column-gap: 15px;
    column-gap: 15px;
    border-top: 1px solid #ddd;
}

.BBPRefundDialog .BBPRDESMsg {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 10px;
}