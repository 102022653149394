.BBPComplaint {
    display: flex;
    flex-direction: column;
    height: 100%;
}
 
.BBPComplaint .BBPCHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} 

.BBPComplaint .BBPCBody {
    flex: 1;
    padding-top: 16px;
    overflow: auto;
}
