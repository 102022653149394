.BBPBPBCategories {
    height: 100%;
}

.BBPBPBCategories .BBPBPBCInner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch {
    padding: 16px 16px;
    display: flex;
    align-items: center;
    position: relative;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 30px;
    color: #000;
    padding-right: 65px;
    text-transform: capitalize;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle .BBPBPBCISTICon {
    display: flex;
    font-size: 24px;
    margin-right: 7px;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISIcon {
    display: flex;
    position: absolute;
    right: 15px;
    top: 8px;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISIcon img {
    width: 125px;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCICat {
    flex: 1;
    padding: 16px;
    padding-top: 0px;
    overflow: auto;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCICInner {
    height: 100%;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCISBtn {
    display: flex;
    justify-content: center;
    padding: 15px;
    padding-top: 0;
}

.BBPBPBCList .BBPBPBCLTitle {
    font-weight: normal;
    font-size: 13px;
    line-height: normal;
    text-align: left;
    color: #707070;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.BBPBPBCList .BBPBPBCLBoxes {
    display: flex;
    flex-wrap: wrap;
}

.BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox {
    width: 90px;
    height: 90px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
    transition: all ease .3s;
    text-decoration: none;
}

.BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox:hover {
    background-color: #f4f7fc;
}

.BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox .BBPBPBCLBIcon {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox .BBPBPBCLBIcon svg {
    font-size: 30px;
}

.BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox .BBPBPBCLBTitle {
    font-weight: normal;
    font-size: 11px;
    color: #707070;
    line-height: 18px;
    text-align: left;
    text-transform: capitalize;
}

.BBPBPBEBList {
    padding: 0 20px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.BBPBPBEBList::-webkit-scrollbar {
    display: none;
}


.BBPBPBEBList .BBPBPBBillerBox {
    width: 130px;
    height: 150px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #d2d2d2;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
    transition: all ease .3s;
    text-decoration: none;
}

.BBPBPBEBList .BBPBPBBillerBox:hover {
    background-color: #f4f7fc;
}

.BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBIcon {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    position: relative;
}

.BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBIcon img {
    width: 100px;
    height: 50px;
    object-fit: contain;
    font-size: 0;
}

.BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBIcon .BBPBPBBBIL {
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #fff;
    border-radius: 100%;
}

.BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBIcon .BBPBPBBBIL>div {
    width: 100%;
    height: 100%;
}

.BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBTitle {
    font-weight: normal;
    font-size: 11px;
    color: #707070;
    line-height: 18px;
    text-align: left;
    text-transform: capitalize;
}

.BBPBPConsumerBill {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPBPConsumerBill .BBPBPCBIInfo {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #d2d2d2;
}

.BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIIIcon {
    display: flex;
}

.BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIIIcon img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    font-size: 0;
}

.BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIITitle {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 24px;
    text-align: left;
    color: #000;
    padding-left: 20px;
    flex: 1 1;
    text-transform: capitalize;
}

.BBPBPConsumerBill .BBPBPCBIView {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVFields {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVFields .BBPBPCBIVField {
    width: calc(20% - 20px);
    margin: 10px;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVTitle {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 26px;
    text-align: left;
    color: #171725;
    margin-bottom: 10px;
    display: block;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVTextField {
    width: 340px;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVTextField input {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 28px;
    text-align: left;
    color: #171725;
}

.BBPBPConsumerBill .BBPBPCBIErr {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #ee2505;
    text-transform: capitalize;
}

.BBPBPConsumerBill .BBPBPCBIBtn {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: 0;
}

.BBPBPConsumerBill .BBPBPCBIBtn button {
    margin: 0 15px;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPBillDetails {
    flex: 1;
    padding: 17.5px;
    border-top: 1px solid #d2d2d2;
}

.BBPBPPPTBDetails .BBPBPPPTBDTitle,
.BBPBPBillDetails .BBPBPBDTitle {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 18px;
    text-align: left;
    color: #000;
    margin-bottom: 10px;
}

.BBPBPBillDetails .BBPBPBDInfo {
    display: flex;
    flex-wrap: wrap;
}

.BBPBPPPTBDetails .BBPBPPPTBDIBox {
    margin-bottom: 10px;
}

.BBPBPPPTBDetails .BBPBPPPTBDIBox:last-child {
    margin-bottom: 0;
}

.BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox {
    width: 245px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox .BBPBPBDIBTitle {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
}

.BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox .BBPBPBDIBSTitle {
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    text-transform: capitalize;
}

.BBPBPBillDetails .BBPBPBillDRoot {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio {
    margin-left: 0;
    margin-right: 25px;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    width: 262px;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio span.MuiTypography-root {
    flex: 1;
    height: 100%;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio .BBPBPBillDRInfo {
    padding-left: 40px;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio .BBPBPBillDRIcon {
    position: absolute;
    top: 20px;
    left: 15px;
    padding: 0;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio .BBPBPBillDRInfo .BBPBPBillDRITitle {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio .BBPBPBillDRInfo .BBPBPBillDRIAmt {
    font-weight: normal;
    font-size: 15px;
    line-height: normal;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
}

.BBPBPBillDetails .BBPBPBillDRoot .BBPBPBillDRadio .BBPBPBillDRIText {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    margin-top: 15px;
}

.BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVError {
    flex: 1;
    padding: 20px;
    border-top: 1px solid #d2d2d2;
}

.BBPBPCBIVError .BBPBPCBIVEInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.BBPBPCBIVError .BBPBPCBIVEInner .BBPBPCBIVEIIcon {
    font-size: 125px;
    color: #d32f2f;
    display: flex;
}

.BBPBPCBIVError .BBPBPCBIVEInner .BBPBPCBIVEIMsg {
    font-size: 18px;
    padding-top: 15px;
    font-weight: 500;
}


.BBPBPPaymentPage {
    height: 100%;
}

.BBPBPPaymentPage .BBPBPPPInner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITitle {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding: 15px 20px;
    border-bottom: 1px solid #d2d2d2;
    position: relative;
}

.BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITitle .BBPBPPPILogo {
    position: absolute;
    display: flex;
    right: 20px;
    top: 6px;
}

.BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITitle .BBPBPPPILogo img {
    width: 130px;
}

.BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITypes {
    flex: 1;
    overflow: auto;
}

.BBPBPPPITypes .BBPBPPPITInner {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol1 {
    width: 200px;
    border-right: 1px solid #d2d2d2;
}

.BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol2 {
    width: calc(100% - 200px);
    height: 100%;
}

.BBPBPPPTabs .BBPBPPPTBtns .BBPBPPPTBSBtn.Mui-disabled {
    display: none;
}

.BBPBPPPTabs .BBPBPPPTBtns .BBPBPPPTBScroller .BBPBPPPTBtn {
    background-color: #175783;
    color: #fff;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px;
}

.BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol2 .BBPBPPPTPanel {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.BBPBPPPTPanel .BBPBPPPTPBU {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.BBPBPPPTPanel .BBPBPPPTBD {
    width: 350px;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #d2d2d2;
}

.BBPBPPPTPanel .BBPBPPPTBD .BBPBPPPTBDetails {
    padding: 20px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInput {
    padding: 20px;
}

.BBPBPPPTPBUInput .BBPBPPPTPBUIInner {
    display: flex;
    align-items: center;
}

.BBPBPPPTPBUInput .BBPBPPPTPBUIInner .BBPBPPPTPBUIField {
    width: 250px;
}

.BBPBPPPTPBUInput .BBPBPPPTPBUIInner .BBPBPPPTPBUIBtn {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 20px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInput .BBPBPPPTPBUIFMsg {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInput .BBPBPPPTPBUIFMsg .BBPBPPPTPBUIFMText {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInfo {
    flex: 1;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPPBtn {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPPBtn button {
    margin: 0 10px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP .BBPBPPPTPBUPPInfo {
    flex: 1 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP .BBPBPPPTPBUPPFooter {
    padding: 20px;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP .BBPBPPPTPBUPPFooter .BBPBPPPTPBUPPFText {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    text-align: center;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP .BBPBPPPTPBUPPFooter .BBPBPPPTPBUPPFBtn {
    display: flex;
    justify-content: center;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUPP .BBPBPPPTPBUPPFooter .BBPBPPPTPBUPPFBtn button {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    color: #d32f2f;
    text-decoration: underline;
}

.BBPBPPPTPBUPP .BBPBPPPTPBUPPInfo .BBPBPPPTPBUPPITitle {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.BBPBPPPTPBUPPInfo .BBPBPPPTPBUPPIClock {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.BBPBPPPTPBUPPInfo .BBPBPPPTPBUPPIClock .CircleProgress {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #f0f0f0;
    box-shadow: 0 0 0px 10px #f0f0f0;
    border: 1px solid #175783;
    padding: 10px;
}

.BBPBPPPTPBUPPIClock .CircleProgress .CPInner {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    transform: scaleX(-1);
    padding: 10px;
}

.BBPBPPPTPBUPPIClock .CircleProgress .CPInner .CPITimer {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: #f0f0f0;
    transform: scaleX(-1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    color: #175783;
}

.BBPBPPPTPanel .BBPBPPPTPBMsg {
    height: 100%;
    width: 100%;
    padding: 15px;
}

.BBPBPPPTPanel .BBPBPPPTPBMsg .BBPBPPPTPBMInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMIIcon {
    display: flex;
    font-size: 150px;
    color: #2e7d32;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMITitle {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-top: 25px;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMIReceipt {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMIReceipt button {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: underline;
    color: #175783;
}

.BBPBPPPTBDetails .BBPBPPPTBDIBox .BBPBPPPTBDIBTitle {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #000000;
}

.BBPBPPPTBDetails .BBPBPPPTBDIBox .BBPBPPPTBDIBSTitle {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    text-transform: capitalize;
}

.BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPPError {
    text-align: center;
    font-size: 18px;
    color: #d32f2f;
    font-weight: 600;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMIIcon.BBPBPPPTPBMIIError {
    color: #d32f2f;
}

.BBPBPPPTBDetails .BBPBPPPTBDBAmt {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d2d2d2;
}

.BBPBPPPTBDetails .BBPBPPPTBDBAmt .BBPBPPPTBDBAInner {
    text-align: center;
}

.BBPBPPPTBDetails .BBPBPPPTBDBAmt .BBPBPPPTBDBAInner .BBPBPPPTBDBAIAmt {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    color: #175783;
}

.BBPBPPPTBDetails .BBPBPPPTBDBAmt .BBPBPPPTBDBAInner .BBPBPPPTBDBAIATitle {
    font-size: 14px;
    font-weight: 600;
    margin-top: 3px;
}

.BBPBPPPTPanel .BBPBPPPTPBMsgOuter {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.BBPBPPPTPanel .BBPBPPPTPBMsgOuter .BBPBPPPTPBMsg {
    flex: 1;
}

.BBPBPPPTPanel .BBPBPPPTPBMsgOuter .BBPBPPPTPBMBtn {
    padding: 15px;
    display: flex;
    justify-content: center;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSMTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 7px;
}

.BBPBPPPTPBMsg .BBPBPSucMain {
    max-width: 400px;
    width: 100%;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    margin-bottom: 15px;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc .BBPBPSInfo {
    flex: 1;
    overflow: auto;
    padding-right: 15px;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc .BBPBPSInfo .BBPBPSITitle {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    line-height: normal;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc .BBPBPSInfo .BBPBPSIDes {
    font-size: 12px;
    line-height: normal;
    color: #6b6a6a;
    text-transform: capitalize;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc .BBPBPSIcon {
    display: flex;
    font-size: 40px;
}

.BBPBPPPTPBMsg .BBPBPSucMain .BBPBPSuc .BBPBPSIcon img {
    width: 40px;
    height: 40px;
}

.BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIILogo {
    position: absolute;
    right: 15px;
    top: 9px;
    display: flex;
}

.BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIILogo img {
    width: 130px;
}

.BBPBPPTILPWA .BBPBPConsumerBill .BBPBPCBIInfo .BBPBPCBIILogo,
.BBPBPPTILPWA .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISIcon {
    display: none;
}

.BBPBPPPTPBMInner .BBPBPPPTPBMIReceipt button.BBPButton {
    color: #fff;
    text-decoration: none;
}

.BBPBPPTILPWA .BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol2 {
    width: 100%;
}

.BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITypes .BBPPWAUPITypes {
    width: 100%;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot.BBPPWAUPIIOth {
    padding-bottom: 75px;
    position: relative;
}

.BBPPWAUPIIGroup .BBPPWAUPIIRoot.BBPPWAUPIIOth .BBPPWAUPIIOField {
    position: absolute;
    top: 50px;
    left: 15px;
    right: 15px;
}

.BBPPWAUPITypes .BBPPWAUPIError {
    text-align: center;
    font-size: 16px;
    color: #d32f2f;
    font-weight: 600;
}

.BBPBPPage .BBPBPPTVBtmNav.BBPBPPTILPWADisable a {
    pointer-events: none;
    opacity: .5;
}

@media only screen and (max-width:767px) {
    .BBPBPConsumerBill .BBPBPCBIInfo {
        padding: 10px;
    }

    .BBPBPConsumerBill .BBPBPCBIView .BBPBPCBISear {
        padding: 10px;
    }

    .BBPBPConsumerBill .BBPBPCBIView .BBPBPCBISear .BBPBPCBISInput {
        padding-right: 0;
        width: 100%;
        padding-bottom: 10px;
    }

    .BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVTextField {
        width: 100%;
    }

    .BBPBPConsumerBill .BBPBPCBIView .BBPBPBillDetails {
        padding: 10px;
    }

    .BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox .BBPBPBDIBTitle {
        font-size: 14px;
    }

    .BBPBPBillDetails .BBPBPBDInfo .BBPBPBDIBox .BBPBPBDIBSTitle {
        font-size: 13px;
    }

    .BBPBPPaymentPage .BBPBPPPInner .BBPBPPPITitle {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
    }

    .BBPBPPPITypes .BBPBPPPITInner {
        flex-direction: column;
        height: auto;
    }

    .BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol1 {
        width: 100%;
        border-right: 0;
    }

    .BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol2 {
        width: 100%;
        height: auto;
    }

    .BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInput {
        padding: 10px;
    }

    .BBPBPPPTPanel .BBPBPPPTPBU {
        width: 100%;
    }

    .BBPBPConsumerBill .BBPBPCBIView .BBPBPCBIVFields .BBPBPCBIVField {
        width: 100%;
    }

    .BBPBPPPTPBUInput .BBPBPPPTPBUIInner .BBPBPPPTPBUIBtn {
        font-size: 14px;
        margin-left: 10px;
    }

    .BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPPBtn {
        padding: 10px;
    }


    .BBPBPPPTPanel .BBPBPPPTBD {
        width: 100%;
        border-left: 0;
    }

    .BBPBPPPTPanel .BBPBPPPTPBU .BBPBPPPTPBUInput .BBPBPPPTPBUIFMsg {
        align-items: flex-start;
    }

    .BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox:nth-child(4n) {
        margin-right: 0;
    }

    .BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox {
        width: 77px;
        height: 90px;
        margin-right: 8px;
        margin-bottom: 9px;
        padding: 10px 7.5px;
    }

    .BBPBPBCList .BBPBPBCLBoxes .BBPBPBCLBox .BBPBPBCLBTitle {
        font-size: 10px;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISBtn {
        padding: 10px;
        padding-top: 0;
    }

    .BBPBPBEBList {
        padding: 0 10px;
    }

    /* .BBPBPBEBList .BBPBPBBillerBox:nth-child(3n) {
        margin-right: 0;
    } */

    .BBPBPBEBList .BBPBPBBillerBox {
        width: 110px;
        min-height: 130px;
        padding: 10px 5px;
        margin-right: 8px;
        margin-bottom: 8px;
    }

    .BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBTitle {
        word-break: break-word;
    }

    .BBPBPBEBList .BBPBPBBillerBox .BBPBPBBBIcon img {
        font-size: 12px;
        word-break: break-word;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1367px) {
    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle {
        padding-right: 25px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle {
        padding-right: 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle {
        padding-right: 25px;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISIcon {
        top: 15px;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISIcon img {
        width: 90px;
    }
}