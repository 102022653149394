.BBPBodyView {
    flex: 1;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
}

.pt-0 {
    padding-top: 0;
}

.BBPBodyView .BBPBVInner {
    height: 100%;
    display: flex;
    flex-direction: row;
}