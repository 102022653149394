.BBPTransaction {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BBPTransaction .BBPTHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 16px;
}

.BBPTransaction .BBPTHead .BBPTHBtn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
    flex: 1;
    justify-content: flex-end;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHBD {
    margin-left: 16px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px 15px;
    text-decoration: none;
    border: 2px solid #175783;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHBD[disabled] {
    opacity: .5;
}

.BBPTransaction .BBPTBody {
    flex: 1;
    padding-top: 16px;
    overflow: auto;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon {
    display: flex;
    margin-right: 15px;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon img {
    width: 100px;
    height: auto;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHDrop {
    display: flex;
    margin-right: 16px;
}


@media only screen and (min-width: 1200px) and (max-width: 1367px) {}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .BBPTransaction .BBPTHead {
        display: block;
    }

    .BBPTransaction .BBPTHead .BBPTHBtn {
        justify-content: flex-start;
        padding-left: 0;
        padding-top: 15px;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .BBPTransaction .BBPTHead {
        display: block;
    }

    .BBPTransaction .BBPTHead .BBPTHBtn {
        padding-left: 0;
        padding-top: 15px;
    }

    .BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon {
        flex: 1;
    }
}