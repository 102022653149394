.BBPBPPoweredBy {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
}

.BBPBPPoweredBy .BBPBPPBTitle {
    font-size: 14px;
    padding-right: 15px;
    font-weight: 500;
    line-height: normal;
}

.BBPBPPoweredBy .BBPBPPBLogo {
    display: flex;
    column-gap: 15px;
}

.BBPBPPoweredBy .BBPBPPBLogo img {
    width: 125px;
    height: 31px;
    object-fit: contain;
}


@media only screen and (max-width:767px) {
    .BBPBPPoweredBy .BBPBPPBLogo img {
        width: 80px;
        height: 28px;
        object-fit: contain;
    }
}