.BBPBPPage {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BBPBPPage .BBPBPPTabs {
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner {
    display: flex;
    align-items: center;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIBtns {
    flex: 1 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIBtns .BBPBPPTIBtn {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0em;
    line-height: 24px;
    text-align: left;
    color: #000;
    text-transform: capitalize;
    padding: 15px 50px;
    position: relative;
    text-decoration: none;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIBtns .BBPBPPTIBtn.BBPBPPTIBActive::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    background-color: #175783;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIIcon {
    display: flex;
    padding: 0 20px;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIIcon img {
    width: 110px;
    height: 40px;
}

.BBPBPPage .BBPBPPTabView {
    flex: 1;
    overflow: auto;
}

.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTILogo {
    flex: 1;
    padding-right: 20px;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCBiller {
    flex: 1;
    overflow: auto;
}

.BBPBPBCategories .BBPBPBCInner .BBPBPBCBiller .infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
}


/*  */

.BBPBPPage .BBPBPPTVBtmNav {
    position: relative;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
    z-index: 1;
}

.BBPBPPage .BBPBPPTVBtmNav a {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #707070;
}

.BBPBPPage .BBPBPPTVBtmNav a.BBPBPPTVBNAct {
    color: #175783;
}

.BBPBPPage .BBPBPPTVBtmNav a span.BBPBPPTVBNIcon {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.BBPBPPage .BBPBPPTVBtmNav a span.BBPBPPTVBNTitle {
    text-align: center;
    font-size: 10px;
    line-height: normal;
    font-weight: 500;
}

.BBPBPPTILogo.BBPBPPTILPWAL {
    display: flex;
}

.BBPBPPTILogo.BBPBPPTILPWAL img {
    width: 115px;
    height: auto;
}

.BBPBPPTILPWA.BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIIcon {
    flex: 1;
    justify-content: flex-end;
}

.BBPBPPage.BBPBPPTILPWA .BBPBPPTabs {
    box-shadow: unset;
}

.BBPBPPage.BBPBPPTILPWA .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch {
    padding-top: 0;
}

@media only screen and (max-width:767px) {


    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch {
        padding: 10px;
        flex-direction: column;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISTitle {
        width: 100%;
        padding-bottom: 5px;
        padding-right: 0;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISInput {
        width: 100%;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCICat {
        padding: 10px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .BBPBPBCategories .BBPBPBCInner .BBPBPBCICat::-webkit-scrollbar {
        display: none;
    }

    .BBPBPPage .BBPBPPTabView {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .BBPBPPage .BBPBPPTabView::-webkit-scrollbar {
        display: none;
    }

    .BBPBPBCategories .BBPBPBCInner .BBPBPBCISearch .BBPBPBCISInput .BBPBPBCISIcon {
        top: 10px;
    }

    .BBPBPPage .BBPBPPTabs {
        border-radius: 0;
    }

    .BBPBPPage .BBPBPPTabs .BBPBPPTInner {
        padding: 5px 0;
    }

    .BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTILogo {
        padding: 0 10px;
    }

    .BBPBPPage .BBPBPPTabs .BBPBPPTInner .BBPBPPTIIcon {
        padding: 0 10px;
    }

    .BBPApp.BBPAPub {
        padding: 0;
    }

    .BBPApp.BBPAPub .BBPBodyView {
        padding: 0;
    }

    .BBPBPPage.BBPBPPTILPWA .BBPBPPPITypes .BBPBPPPITInner,
    .BBPBPPage.BBPBPPTILPWA .BBPBPPPITypes .BBPBPPPITInner .BBPBPPPITCol2 {
        height: 100%;
    }

    .BBPBPPage.BBPBPPTILPWA .BBPBPPPITypes .BBPBPPPTPBMInner .BBPBPSucMain {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .BBPBPPage.BBPBPPTILPWA .BBPBPPPITypes .BBPBPPPTPBMInner .BBPBPSucMain .BBPBPPPTPBMIReceipt {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

}