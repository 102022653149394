.BBPSearchMain {
    display: flex;
    align-items: center;
}

.BBPSearchBox {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 300px;
}

.BBPSearchBox>svg {
    position: absolute;
    font-size: 20px;
    top: 6px;
    left: 13px;
    color: #5a607f;
}

.BBPSearchBox .BBPSBClose {
    position: absolute;
    right: 6px;
    top: 8px;
    font-size: 16px;
    padding: 0px;
    z-index: 1;
    background-color: #fff;
    color: #5a607f;
}

.BBPSearchBox .BBPSBInput {
    padding: 6px 10px 6px 40px;
    margin: 0;
    border: 0;
    display: block;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #5a607f;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    background-color: transparent;
    outline: 0;
    width: 100%;
}

.BBPSearchBox.BBPSBSStart .BBPSBInput {
    padding-right: 30px;
}

.BBPSearchMain>button {
    font-size: 22px;
    margin-left: 10px;
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .BBPSearchBox {
        width: 250px;
    }
}