.BBPBackdrops {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BBPBackdrops .BBPBLoader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.BBPBackdrops .BBPBTitle {
    text-align: left;
    margin-top: 15px;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.BBPBackdrops .BBPBTitle span {
    margin-right: 5px;
}

.BBPBackdrops .BBPBTitle::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "..."; 
    width: 0px;
    letter-spacing: 5px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.5em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.5em;    
    }
  }