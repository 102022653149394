.BBPUserDropdown {
    display: flex;
    align-items: center;
    max-width: 200px;
    overflow: hidden;
    margin-left: 40px;
    cursor: pointer;
    user-select: none;
}

.BBPUserDropdown .BBPUDImg {
    display: flex;
}

.BBPUserDropdown .BBPUDName {
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #131523;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
}

.BBPUserDropdown .BBPUDIcon {
    display: flex;
    align-items: center;
    color: #175783;
}

.BBPUDPopper {
    padding-top: 10px;
    z-index: 9999;
}

.BBPUDPopper .BBPUDMenus {
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
}

.BBPUDPopper .BBPUDMenus ul li {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}