html,
body,
img,
fieldset,
abbr,
acronym {
  border: 0;
  max-width: 100%;
  width: auto;
}

body {
  color: #000;
  line-height: 32px;
  margin: 0;
  background-color: #F4F7FC;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
}

html,
body,
.BBPRoot {
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Scroll Bar */

* {
  scrollbar-width: thin;
  scrollbar-color: #00000033 #00000018;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  transition: all ease 0.2s;
  overflow: auto;
  margin: auto;
}

*::-webkit-scrollbar-track {
  border-radius: 50px;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: scroll;
  background: #c4c5c8;
}

*::-webkit-scrollbar-thumb {
  background: #0000;
  border-radius: 50px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #00000050;
  transition-duration: 10s;
}

.BBPApp {
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
}

.BBPApp .BBPAInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

a.BBPTableBtn,
button.BBPTableBtn {
  background-color: #fff;
  border-radius: 4px;
  color: #5A607F;
  font-family: Poppins;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  box-shadow: unset;
  line-height: normal;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

a.BBPTableBtn span {
  font-size: 16px;
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

a.BBPTableBtn span svg,
button.BBPTableBtn span svg.MuiSvgIcon-root {
  font-size: 16px;
}

a.BBPTableBtn:hover,
button.BBPTableBtn:hover {
  background-color: #175783;
  color: #fff;
}

button.BBPButton {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: normal;
  text-align: left;
  color: #fff;
  border-radius: 10px;
  background-color: #175783;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 35px;
  text-decoration: none;
  border: 2px solid #175783;
}

button.BBPButton[disabled] {
  color: #fff;
  opacity: .5;
  background-color: #175783;
}

button.BBPButton:hover {
  background-color: #175783;
  border: 2px solid #175783;
}

button.BBPButton.BBPBRed {
  background-color: #ee2505;
  border: 2px solid #ee2505;
}

button.BBPButton.BBPOButton {
  background: unset;
  color: #175783;
}

.BBPApp.BBPLP {
  padding: 0;
}

.BBPApp.BBPLP .BBPBodyView {
  padding: 0;
}

button.BBPButton .MuiLoadingButton-loadingIndicator {
  color: #fff;
}

/* Forms */

.BBPForm {
  width: 100%;
}

.BBPForm form.rjsf {
  display: block;
  position: relative;
}

.BBPForm form.rjsf button.BBPFBtn {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  padding: 0;
  border: 0;
  opacity: 0;
  z-index: -9;
}

.BBPForm form.rjsf .field-object>fieldset {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object>fieldset .form-group {
  width: 33.33%;
  padding: 7.5px;
}

.BBPForm form.rjsf .field-object>fieldset .form-group label.control-label {
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0em;
  line-height: normal;
  text-align: left;
  color: #171725;
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.BBPForm form.rjsf .field-object>fieldset .form-group label.control-label span.required {
  color: #ee2505;
  display: inline-block;
  margin-left: 5px;
}

.BBPForm form.rjsf .field-object>fieldset .form-group .form-control {
  padding: 12px 15px;
  font-size: 14px;
  line-height: 21px;
  text-transform: inherit;
  font-weight: 500;
  color: #171725;
  border: 1px solid rgba(0, 0, 0, 0.23);
  justify-content: flex-start;
  position: relative;
  display: block;
  width: 100%;
  height: 47px;
  border-radius: 4px;
  outline: 0;
  height: 47px;
}

.BBPForm form.rjsf .field-object>fieldset .form-group textarea.form-control {
  resize: unset;
}

.BBPForm form.rjsf .field-object>fieldset .form-group select.form-control {
  appearance: none;
  padding-right: 40px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0z'/><path d='M12 16l-6-6h12z' fill='rgb(23 23 37)'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 7px) center;
  background-size: 24px;
  cursor: pointer;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array {
  width: 100%;
  padding: 0;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object {
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object>legend {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 0 7.5px;
  padding-top: 7.5px;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row {
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row>.array-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row>.array-item .col-xs-9 {
  flex: 1;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row>.array-item .col-xs-9 .field-object {
  width: 100%;
  padding: 0;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row>.array-item .array-item-toolbox {
  padding: 7.5px;
  padding-top: 42px;
  display: flex;
  align-items: flex-start;
  width: 75px;
  justify-content: flex-end;
}


.BBPForm form.rjsf .field-object fieldset .form-group.field-array .row>.array-item .array-item-toolbox .btn-group button {
  border: 0;
  padding: 7px !important;
  display: flex;
  cursor: pointer;
  font-size: 24px;
  color: #818181;
  background-color: unset;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .field-array-of-object .array-item-list:empty+button.array-item-add {
  bottom: 0;
}

.BBPForm form.rjsf .field-object>fieldset .form-group ul.error-detail {
  margin: 0;
  padding-left: 20px;
}

.BBPForm form.rjsf .field-object>fieldset .form-group ul.error-detail li.text-danger {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  text-transform: capitalize;
}

.BBPForm form.rjsf .field-object>fieldset .form-group.field-error .form-control {
  border-color: #d32f2f;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .BBPFAB {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.BBPForm form.rjsf .field-object fieldset .form-group.field-array .BBPFAB .array-item-add {
  border-color: #175783;
  color: #175783;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 8px;
  align-items: center;
  line-height: normal;
  padding: 7px 15px;
}

.BBPTPopper .BBPTooltip {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}

.BBPPwaAuth {
  height: 100%;
  padding: 16px;
}

.BBPPwaAuth .BBPPAInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPForm form.rjsf .field-object>fieldset .form-group.BBPFFWidth {
  width: 100%;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .BBPForm form.rjsf .field-object>fieldset .form-group {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .BBPForm form.rjsf .field-object>fieldset .form-group {
    width: 50%;
  }

  .BBPForm form.rjsf .field-object>fieldset .form-group.BBPFUTab {
    width: 100%;
  }
}