.BBPRCDialog {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.BBPRCDialog .BBPRCDHead {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
}

.BBPRCDialog .BBPRCDHead .BBPRCDHTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    padding: 10px 15px;
    flex: 1;
    line-height: normal;
}

.BBPRCDialog .BBPRCDForm {
    flex: 1 1;
    overflow: auto;
    padding: 7.5px;
}

.BBPRCDialog .BBPRCDErr {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 10px;
    color: #ee2505;
}

.BBPRCDialog .BBPRCDBtn {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    grid-column-gap: 15px;
    column-gap: 15px;
    border-top: 1px solid #ddd;
}

.BBPRCDialog .BBPRCDView {
    flex: 1;
    overflow: auto;
}

.BBPRCDialog .BBPRCDView .BBPRCDVStatus {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.BBPRCDialog .BBPRCDView .BBPRCDVStatus.Pending {
    background-color: #ed6c02;
}

.BBPRCDialog .BBPRCDView .BBPRCDVStatus.Completed {
    background-color: #2e7d32;
}

.BBPRCDialog .BBPRCDView .BBPRCDVStatus.UnderProcess {
    background-color: #ffbf00;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow {
    display: flex;
    flex-wrap: wrap;
    padding: 7.5px;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol {
    width: 50%;
    padding: 5px 7.5px;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol.BBPRCDVRCFull {
    width: 100%;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol .BBPRCDVRCTitle {
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol .BBPRCDVRCSTitle {
    font-size: 13px;
    line-height: normal;
    color: #000;
    word-break: break-word;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCol .BBPRCDVRCSTitle.BBPRCDVRCFS>div {
    color: #fff;
    background-color: #673ab7;
    font-size: 10px;
    line-height: normal;
    padding: 3px 7px;
    border-radius: 4px;
    display: inline-block;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTTitle {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f6fa;
    font-size: 14px;
    padding: 5px 15px;
    line-height: normal;
    color: #000;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPD {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    text-decoration: underline;
    line-height: normal;
    margin-bottom: 5px;
    border-top: 1px solid #ddd;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow {
    width: 100%;
    display: flex;
    line-height: normal;
    padding: 3px 5px;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow .BBPRCDVRCPDRCTitle {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    padding-right: 15px;
    color: #000;
}

.BBPRCDialog .BBPRCDView .BBPRCDVRow .BBPRCDVRCPDRow .BBPRCDVRCPDRCSTitle {
    flex: 1 1;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.07em;
    color: #2a2a2a;
    line-height: normal;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTStatus {
    padding: 5px 15px;
    line-height: normal;
    background-color: #000;
    color: #fff;
    font-size: 13px;
    text-align: center;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTStatus.Success {
    background-color: #2e7d32;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTStatus.Pending {
    background-color: #ed6c02;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTStatus.Failed {
    background-color: #ee2505;
}

.BBPRCDialog .BBPRCDView .BBPRCDVTStatus.Canceled {
    background-color: #d32f2f;
}